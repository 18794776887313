import React, { Component } from 'react'
import Code from '../components/code'

const parseProperties = response => {
  let schema = {}
  if (response.properties) {
    const properties = response.properties
    Object.keys(properties).forEach(prop => {
      let value = null
      switch (properties[prop].type) {
        case "integer":
          value = 0
          if (properties[prop].minimum) {
            value = properties[prop].minimum
          }
          break
        case "string":
          switch(properties[prop].format) {
            case "uri":
            case "url":
              value = "http://www.example.com"
              break
            case "email":
              value = "user@example.com"
              break
            case "date-time":
              value = new Date()
              break
            case "uuid":
              value = "770bba28-9f80-4d40-9c51-6e022364af91"
              break
            case "decimal":
              value = "0.00"
              break
            default:
              value = "string"
              break
          }
          break;
        case "boolean":
          value = false
          break
        case "file":
          value = "string"
          break
        case "object":
          if (properties[prop].properties) {
            value = parseProperties(properties[prop])
          }
          if (properties[prop].definition) {
            value = parseProperties(properties[prop].definition)
          }
          break
        case "array":
          value = []
          if (properties[prop].items && properties[prop].items._ref && properties[prop].items.definition) {
            value = [parseProperties(properties[prop].items.definition)]
          }
          if (properties[prop].items && properties[prop].items.properties) {
            value = parseProperties(properties[prop].items)
          }
          break
        default:
          break
      }
      if (properties[prop].name) {
        schema[properties[prop].name] = value
        if (properties[prop].name === 'meta') {
          schema[properties[prop].name] = {}
        }
      } else {
        schema[prop] = value
      }
    })
  } else if (response.type) {
    switch (response.type) {
      case "integer":
        schema = 0
        if (response.minimum) {
          schema = response.minimum
        }
        break
      case "string":
        switch(response.format) {
          case "uri":
          case "url":
            schema = "http://www.example.com"
            break
          case "email":
            schema = "user@example.com"
            break
          case "date-time":
            schema = new Date()
            break
          case "uuid":
            schema = "770bba28-9f80-4d40-9c51-6e022364af91"
            break
          case "decimal":
            schema = "0.00"
            break
          default:
            schema = "string"
            break
        }
        break;
      case "boolean":
        schema = false
        break
      case "object":
        if (response.properties) {
          schema = parseProperties(response)
        }
        if (response.definition) {
          schema = parseProperties(response.definition)
        }
        schema = {}
        break
      case "file":
        schema = "string"
        break
      case "array":
        schema = []
        if (response.items && response.items._ref && response.items.definition) {
          schema = [parseProperties(response.items.definition)]
        }
        if (response.items && response.items.properties) {
          schema = parseProperties(response.items)
        }
        break
      default:
        break
    }
  }
  return schema
}

export default class SpecPathResponseSample extends Component {
  render() {
    const { response } = this.props
    const schema = parseProperties(response)
    return (
      <>
        <blockquote><h3>Example Response</h3></blockquote>
        <Code code={JSON.stringify(schema, null, 2)} language={"json"} />
      </>
    )
  }
}