import React from 'react'
import PropTypes from 'prop-types'
import g from 'glamorous'
import SpecDefinition from './SpecDefinition'


const getActiveStyle = active => {
  return {
    height: "1.5em",
    width: "1.5em",
    verticalAlign: "middle",
    float: "left",
    transition: "transform 0.2s ease-out 0s",
    transform: active ? "rotateZ(0deg)" : "rotate(-90deg)",
  }
}

const tabStyle = () => ({
  clear: "none !important",
  marginLeft: "40px",
  marginBottom: "4px",
  lineHeight: "1.5em",
})

const tabHeaderStyle = (statusCode, definition) => {
  let color = "rgb(229, 57, 53)"
  let bgColor = "rgb(229, 57, 53, 0.1)"
  if (statusCode >= 200 && statusCode < 400) {
    color = "rgb(0, 170, 19)"
    bgColor = "rgb(0, 170, 19, 0.1)"
  }
  const cursor = definition ? "pointer" : "default"
  return {
    borderRadius: "2px",
    marginBottom: "4px",
    lineHeight: "1.5em",
    padding: "10px",
    cursor: cursor,
    color: color,
    backgroundColor: bgColor
  }
}

export const SpecPathResponse = ({ verb, active, toggleResponse, response }) => {
  let definition = null
  if (response.children.filter(c => c.__typename === "OpenApiSpecDefinition").length === 1) {
    definition = response.children.filter(c => c.__typename === "OpenApiSpecDefinition")[0]
  }
  if (!definition && response.schema && response.schema.properties) {
    response.schema.properties.forEach(prop => {
      if (prop.items && prop.items._ref && !prop.description) {
        prop.description = `Array of ${prop.items._ref.split(".").pop()} items`
      }
    })
    definition = response.schema
  }
  return (
    <g.Div className="spec-responses" css={tabStyle}>
      <g.Div display="flex" alignItems="center">
        {definition ? (
          <g.Div
            css={tabHeaderStyle(response.statusCode, true)}
            onClick={() => {
              toggleResponse(response.statusCode)
            }}
            flex="1 1 100%"
          >
            <g.Svg css={getActiveStyle(active === response.statusCode)} fill="rgb(0, 170, 19)" version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0"><polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "></polygon></g.Svg>
            <strong>{response.statusCode}</strong> {response.description}
          </g.Div>
        ) : (
          <g.Div
            css={tabHeaderStyle(response.statusCode)}
            flex="1 1 100%"
          >
            <g.Svg css={getActiveStyle(true)} fill="rgb(229, 57, 53)" version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0"><rect x="5" y="10" width="10" height="2" /></g.Svg>
            <strong>{response.statusCode}</strong> {response.description}
          </g.Div>
        )}
      </g.Div>
      {definition && active === response.statusCode &&
        <g.Div marginBottom="20px">
          <SpecDefinition definition={definition} />
        </g.Div>
      }
    </g.Div>
  )
}

SpecPathResponse.propTypes = {
  verb: PropTypes.string.isRequired,
  active: PropTypes.string,
  toggleResponse: PropTypes.func.isRequired,
  response: PropTypes.object.isRequired,
}

class SpecPathResponses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: null
    }
    this.toggleResponse = this.toggleResponse.bind(this)
  }

  toggleResponse(response) {
    if (this.state.active === response) {
      this.setState({ active: null })
    } else {
      this.setState({ active: response })
    }
  }

  render() {
    const { responses, verb } = this.props
    const { active } = this.state

    return (
      <>
        <h3>Responses</h3>
        {
          responses.map((response, rid) => (
            <SpecPathResponse
              key={`resp-${rid}`}
              verb={verb}
              active={active}
              toggleResponse={this.toggleResponse}
              response={response}
            />
          ))
        }
      </>
    )
  }
}

SpecPathResponses.propTypes = {
  responses: PropTypes.array.isRequired,
}

export default SpecPathResponses