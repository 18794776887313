import React from 'react'
import PropTypes from 'prop-types'
import g from 'glamorous'
import Verb from './Verb'
import SpecPathResponses from './SpecPathResponses'
import SpecPathParameters from './SpecPathParameters'
import SpecPathResponseSample from './SpecPathResponseSample'


export const SpecPath = ({ path, definitions }) => {
  const specPath = { ...path }
  const responses = specPath.children.filter(c => c.__typename === "OpenApiSpecResponse")
  const okReponse = responses.find(r => r.statusCode >=200 && r.statusCode < 300)
  let schema = null
  if (okReponse) {
    if (okReponse.children.filter(c => c.__typename === "OpenApiSpecDefinition").length) {
      schema = okReponse.children.filter(c => c.__typename === "OpenApiSpecDefinition")[0]
      schema.properties.filter(prop => prop._ref || prop.items).forEach(prop => {
        if (prop.items && prop.items._ref) {
          prop.items.definition = definitions.find(d => d.name === prop.items._ref)
        }
        if (prop._ref) {
          const def = `${specPath.parent.id}.definition.${prop._ref.replace("#/definitions/", "")}`
          prop.type = "object"
          prop.definition = definitions.find(d => d.id === def)
        }
      })
    } else if (okReponse.schema) {
      if (okReponse.schema.properties) {
        okReponse.schema.properties.filter(prop => prop._ref || prop.items).forEach(prop => {
          if (prop.items && prop.items._ref) {
            prop.items.definition = definitions.find(d => d.id.indexOf(prop.items._ref) === 8)
          }
          if (prop._ref) {
            const def = `${specPath.parent.id}.definition.${prop._ref.replace("#/definitions/", "")}`
            prop.definition = definitions.find(d => d.id === def)
            prop.type = "object"
          }
        })
      }
      schema = okReponse.schema
    }
  }
  specPath.parameters = specPath.parameters ? [ ...specPath.parameters ] : []
  specPath.parameters.filter(p => p.schema && p.schema._ref).forEach(p => {
    if (p.schema){
      const def = `${specPath.parent.id}.definition.${p.schema._ref.replace("#/definitions/", "")}`
      const ref = definitions.find(d => d.id === def)
      ref.properties.forEach(prop => {
        specPath.parameters.push({...prop, in: 'body'})
      })
    }
  })
  specPath.parameters.filter(p => p._ref).forEach(p => {
    if (p._ref && !p.type) {
      p.type = "object"
    }
  })
  return (
    <div className="spec-path">
      <h3>HTTP Request</h3>
      <p><Verb value={specPath.verb} /><code fontWeight="600">{specPath.fullPath}</code></p>
      {schema && <SpecPathResponseSample response={schema} />}
      {specPath.parameters && <SpecPathParameters parameters={specPath.parameters.filter(p => !p.schema)} />}
      {responses && <SpecPathResponses verb={specPath.verb} responses={responses} />}
    </div>
  )
}

SpecPath.propTypes = {
  path: PropTypes.object.isRequired,
}

const SpecPaths = ({ tag, paths }) => (
  <div>
    <h2>{tag}</h2>
    {paths.map(p => (
      <g.Div key={`${p.name}-${p.verb}`} marginBottom="1rem">
        <SpecPath path={p} />
      </g.Div>
    ))}
  </div>
)

SpecPaths.propTypes = {
  tag: PropTypes.string.isRequired,
  paths: PropTypes.array.isRequired,
}

export default SpecPaths
