import React from 'react'
import PropTypes from 'prop-types'
import g from 'glamorous'

const superScriptStyle = (type) => {
  let color
  switch(type) {
    case 'required':
      color = "rgb(229, 57, 53)"
      break
    case 'nullable':
      color = "rgb(49, 149, 166);"
      break
    case 'tag':
      color = "rgb(49, 149, 166);"
      break
    default:
      color = "rgb(0, 0, 0)"
      break
  }
  return {
    verticalAlign: "middle",
    color: color,
    fontSize: "0.9em",
    fontWeight: "normal",
    marginLeft: "16px",
    lineHeight: 1,
  }
}

const SpecDefinitionProperty = (props) => (
  <tr>
    <td><p>
      <span className="spacer"></span>{props.name}
      <g.P lineHeight="1 !important">
        {props.required && <g.Span css={superScriptStyle('required')}>required</g.Span>}
        {props.x_nullable && <g.Span css={superScriptStyle('nullable')}>nullable</g.Span>}
      </g.P>
    </p></td>
    <td>
      <p marginBottom="0">
        <strong>{props.type}{props.items && props.items.type && ` of ${props.items.type}s`}</strong>
        {props.maxLength && !props.minLength && <span className="tag">{`<=`} {props.maxLength} characters</span>}
        {props.minLength > 1 && !props.maxLength && <span className="tag">{`>=`} {props.minLength} characters</span>}
        {props.minLength === 1 && !props.maxLength && <span className="tag">non-empty</span>}
        {props.minLength && props.maxLength && <span className="tag">[ {props.minLength}...{props.maxLength} ] characters</span>}
      </p>
      {props.title ? `${props.title}` : ''}
      {props.items && props.items.title ? `${props.items.title}` : ''}{props.description && (props.title || (props.items && props.items.title)) ? ` - ${props.description}` : props.description}
      {props.items && props.items.enum &&
        <p marginBottom="0">
          Choices: {props.items.enum.map((v, vidx) => <span key={`enum-${props.id}`} className="tag enum">{v === "dummy content" ? `[ 1,2,3...2147483648 ]` : v}</span>)}
        </p>
      }
      {props.enum &&
        <p marginBottom="0">
          Choices: {props.enum.map((v, vidx) => <span key={`enum-${props.id}`} className="tag enum">{v === "dummy content" ? `[ 1,2,3...2147483648 ]` : v}</span>)}
        </p>
      }
    </td>
  </tr>
)

SpecDefinitionProperty.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  format: PropTypes.string,
}

const SpecDefinition = ({ definition }) => {
  return (
  <React.Fragment>
    <g.P fontWeight="600" fontStyle="italic" marginBottom="0">
      {definition.name}
    </g.P>
    <table className="component-props-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {definition.properties.map((p, i) => (
          <SpecDefinitionProperty
            key={`property-${i}`}
            {...p}
          />
        ))}
      </tbody>
    </table>
  </React.Fragment>
)}

SpecDefinition.propTypes = {
  definition: PropTypes.object.isRequired,
}

export default SpecDefinition
