import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SpecInformation from '../spec/SpecInformation'
import { SpecPath } from '../spec/SpecPaths'
import{ graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'


class Api extends Component {
  render() {
    const path = this.props.data.openApiSpecPath
    const definitions = this.props.data.allOpenApiSpecDefinition

    return (
      <Layout>
        <SEO title={`${path.summary}${path.tag ? ` | ${path.tag}` : ''}`}></SEO>
        <div className="content">
          <SpecInformation
            title={path.summary}
            description={path.description}
          />
          <SpecPath path={path} definitions={definitions.nodes} />
        </div>
      </Layout>
    )
  }
}

Api.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Api

export const query = graphql`
  query ApiQuery($id: String!, $operationId: String!, $tag: String!) {
    openApiSpecPath(id: { eq: $id }, operationId: { eq: $operationId }, tag: { eq: $tag }) {
      name
      verb
      operationId
      summary
      description
      fullPath
      tag
      parameters {
        description
        in
        name
        required
        type
        schema {
          _ref
        }
      }
      children {
        ... on OpenApiSpecResponse {
          id
          statusCode
          description
          children {
            ... on OpenApiSpecDefinition {
              id
              name
              properties {
                items {
                  _ref
                  enum
                  maximum
                  minimum
                  title
                  type
                  x_nullable
                }
                _ref
                description
                format
                name
                type
                maxLength
                maximum
                minLength
                minimum
                readOnly
                title
                x_nullable
                required
                enum
              }
            }
          }
          schema {
            _ref
            type
            required
            properties {
              items {
                _ref
              }
              description
              format
              name
              type
              properties {
                property_types {
                  items {
                    properties {
                      locations {
                        items {
                          type
                        }
                        type
                      }
                      property_type {
                        type
                      }
                    }
                    type
                  }
                  type
                }
              }
            }
          }
        }
      }
      parent {
        ... on OpenApiSpec {
          id
          name
        }
      }
    }
    allOpenApiSpecDefinition {
      nodes {
        name
        id
        properties {
          items {
            _ref
            enum
            maximum
            minimum
            title
            type
            x_nullable
          }
          _ref
          description
          format
          name
          type
          maxLength
          maximum
          minLength
          minimum
          readOnly
          title
          x_nullable
          required
          enum
        }
      }
    }
  }
`
