import React from 'react'
import PropTypes from 'prop-types'
import g from 'glamorous'
import groupBy from 'lodash.groupby'
import SpecDefinition from './SpecDefinition'

const superScriptStyle = {
  verticalAlign: "middle",
  color: "rgb(229, 57, 53)",
  fontSize: "0.9em",
  fontWeight: "normal",
  marginLeft: "16px",
  lineHeight: 1,
}

const SpecPathParameter = ({
  name,
  source,
  description,
  type,
  format,
  required,
}) => {
  return (
    <tr>
      <td>
        <g.P marginBottom="0.5rem">
          <span className="spacer"></span>
          {name}{' '}
          {required && <g.Div css={superScriptStyle}>required</g.Div>}
        </g.P>
      </td>
      <td>
        {type && (
          <g.P fontWeight="600" marginBottom="0">
            {type}
          </g.P>
        )}
        {description}</td>
    </tr>
  )
}

SpecPathParameter.propTypes = {
  name: PropTypes.string.isRequired,
  source: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  format: PropTypes.string,
  required: PropTypes.bool,
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const SpecPathParameters = ({ parameters }) => {
  const paramGroups = groupBy(parameters, p => p.in)
  return Object.keys(paramGroups).map((group, gid) => {
    const params = paramGroups[group]
    return (<SpecDefinition definition={{ properties: params }} name={`${capitalize(group)} Parameters`} />)
  })
}

SpecPathParameters.propTypes = {
  parameters: PropTypes.array.isRequired,
}

export default SpecPathParameters
